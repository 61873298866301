<template>
  <div id="document-uploader">
    <div class="ui four basic doubling cards">
      <ErrorMessage :error="error" />
      <div
        v-for="document in documents"
        v-if="!error"
        :key="document.id"
        class="picture-frame card"
      >
        <div>
          <a
            v-if="document.thumbnail"
            class="picture"
            :href="document.src"
            target="_blank"
            :data-lightbox="document.category === 'picture' ? document.id : null"
            :style="{ backgroundImage: `url(${document.thumbnail})` }"
          >
            <div class="filetype">
              <p>
                {{ document.type ? document.type.toUpperCase() : '' }}
              </p>
            </div>
          </a>
          <a
            v-else
            class="picture"
            :href="document.src"
            :target="document.category === 'pdf' ? '_blank' : '_self'"
            :style="documentBackground(document)"
          >
            <div class="filetype">
              <p>
                {{ document.type ? document.type.toUpperCase() : '' }}
              </p>
            </div>
          </a>
          <div
            v-if="canDelete"
            class="frame-control"
          >
            <a :href="document.delete">
              <img
                alt="Löschen"
                src="/images/block_inv.png"
              >
            </a>
          </div>
          <div
            class="caption"
            :title="document.name"
          >
            {{ document.name }}
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div
      v-if="!readonly"
      class="aTable-full transparent"
    >
      <div class="aTableCell">
        <select
          v-if="metadata !== null"
          v-model="kind"
        >
          <option value="">
            -
          </option>
          <option
            v-for="[key, entry] in metadata.entries()"
            :key="key"
            :value="key"
          >
            {{ entry }}
          </option>
        </select>
        <upload
          :server="server"
          @processfile="handleProcessFile"
          @addfile="handleAddFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/Document/UploadComponent'
import { path, request } from '@/js/request'
import { DOCUMENT_FILE_DELETE, DOCUMENT_SHOW_CONTENT, DOCUMENT_SHOW_THUMBNAIL } from 'routes'
import ErrorMessage from '@/components/_partials/Atom/ErrorMessage.vue'

export function documentBackground (document) {
  switch (document.category) {
    case 'pdf':
      return { backgroundColor: '#A00000' }
    case 'office':
      return { backgroundColor: '#E83D00' }
    case 'archive':
      return { backgroundColor: '#6000A0' }
    default:
      return { backgroundColor: '#FFD000' }
  }
}

export function createBuildingMetadata () {
  const metadata = new Map()

  metadata.set('photo', 'Bild')
  metadata.set('apl', 'APL Doku')
  metadata.set('abnahmeprotokoll', 'Abnahmeprotokoll')
  metadata.set('konnektierung', 'Foto innen, außen, Konnektierung')
  return metadata
}

export default {
  components: {
    ErrorMessage,
    Upload
  },
  props: {
    server: {
      type: String,
      required: true
    },
    metadata: {
      type: Map,
      required: false,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: () => false
    },
    deleteRoute: {
      type: String,
      required: false,
      default: null
    },
    routeParams: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      error: null,
      documents: [],
      kind: ''
    }
  },
  async created () {
    await this.create()
  },
  methods: {
    searchParams () {
      if (this.deleteRoute !== null && this.routeParams !== null) {
        return {
          ...this.routeParams,
          route: this.deleteRoute
        }
      }
      return {}
    },
    async create () {
      try {
        const { json } = await request(this.server)
        this.documents = json.map(file => {
          return {
            ...file,
            delete: path(DOCUMENT_FILE_DELETE, {
              ...this.searchParams(),
              document: file.id
            }),
            src: path(DOCUMENT_SHOW_CONTENT, { document: file.id }),
            thumbnail: file.thumbnail ? path(DOCUMENT_SHOW_THUMBNAIL, { document: file.id }) : null
          }
        })
      } catch (e) {
        this.error = e
      }
    },
    handleAddFile (error, file) {
      if (error || this.metadata === null) {
        return
      }
      file.setMetadata('kind', this.kind)
    },
    handleProcessFile (error, file) {
      if (error !== null) {
        throw error
      }
      this.create()
    },
    documentBackground
  }
}
</script>
